<script>
export default {
  name: "Traffic"
}
</script>

<template>
  <div class="container leads-traffic-container">
    <div class="wrapper traffic-wrapper">
      <div class="row">
        <div class="inner-wrapper">
          <div class="traffic-title-container">
            <h2 class="traffic-title">5 Simple Steps:</h2>
          </div>

          <ul class="traffic-list">
            <li class="odd">
              <img class="list-arrow" src="../../assets/img/svg/list-arrow.svg" alt="">
              <p>
                <strong class="strong-text">Discovery.</strong> Book a call with us and we’ll go over your goals and make sure we’re a good fit for your firm
              </p>
            </li>
            <li class="even">
              <img class="list-arrow" src="../../assets/img/svg/list-arrow.svg" alt="">
              <p>
                <strong class="strong-text">Set up.</strong> We get the criteria you are looking for dialed in and set up marketing campaigns on your behalf.
              </p>
            </li>
            <li class="odd">
              <img class="list-arrow" src="../../assets/img/svg/list-arrow.svg" alt="">
              <p>
                <strong class="strong-text">Production.</strong> We advertise through a variety of methods and qualify candidates using our proprietary lead nurturing system.
              </p>
            </li>
            <li class="even">
              <img class="list-arrow" src="../../assets/img/svg/list-arrow.svg" alt="">
              <p>
                <strong class="strong-text">Delivery.</strong> If the client matches your criteria, geo targeting and area of law, we deliver them in real time to your phone, email or CRM. You only pay per result.
              </p>
            </li>
            <li class="odd">
              <img class="list-arrow" src="../../assets/img/svg/list-arrow.svg" alt="">
              <p>
                <strong class="strong-text">Results.</strong> Increase your profit, reduce your workload, and be on your way to creating the business you have always wanted!
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../assets/styles/component_styles/traffic.scss';
</style>