<script>
export default {
  name: "ServicesInformation"
}
</script>

<template>
  <div class="container services-information-container">
    <div class="wrapper">

      <div class="row services-row">
        <div class="box icon-box">
          <div class="services-header-container">
            <img class="heading-icon" src="../../assets/img/svg/growth.svg" alt="time"/>
            <h3 class="heading heading-services">GROWTH</h3>
          </div>
        </div>

        <div class="box services-box">
          <p class="sub-heading">
            <strong class="strong-text">Grow your firm fast.</strong> Focus on cases instead of spending
            countless hours on marketing with limited results. We will spearhead your
            marketing campaigns and deliver. There are thousands of auto accidents,
            lawsuits, worker’s comp cases and more every single day.
          </p>
        </div>
      </div>

      <hr class="divider"/>

      <div class="row services-row">
        <div class="box mobile icon-box">
          <div class="services-header-container">
            <img class="heading-icon" src="../../assets/img/svg/five-stars-quality-symbol.svg" alt="time"/>
            <h3 class="heading heading-services">QUALITY</h3>
          </div>
        </div>

        <div class="box services-box">
          <p class="sub-heading">
            <strong class="strong-text">Quality leads</strong> drive revenue. Using our proprietary
            marketing funnels, we deliver only candidates who have self indicated they are qualified.
            This saves your firm time and effort wasted on bad leads.
          </p>
        </div>

        <div class="box desktop icon-box">
          <div class="services-header-container text-align-center">
            <img class="heading-icon" src="../../assets/img/svg/five-stars-quality-symbol.svg" alt="time"/>
            <h3 class="heading heading-services">QUALITY</h3>
          </div>
        </div>
      </div>

      <hr class="divider"/>

      <div class="row services-row">
        <div class="box icon-box">
          <div class="services-header-container">
            <img class="heading-icon" src="../../assets/img/svg/balance-law.svg" alt="time"/>
            <h3 class="heading heading-services">EXPERTISE</h3>
          </div>
        </div>

        <div class="box services-box">
          <p class="sub-heading">
            We generate leads in many areas of law including:
            <strong class="strong-text">
              Auto Accident Injury, Mass Tort, Worker’s Compensation, Personal Injury, Employment Law.
            </strong>
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../assets/styles/component_styles/services_information';
</style>