<script>
export default {
  name: "CreateAccount",
}
</script>

<template>
  <div class="container create-account-container">
    <div class="wrapper">
      <div class="row first-content">
        <div class="box">
          <div class="info">
            <h3 class="heading">
              Your Case
              <br/>
              Origination Experts
            </h3>
            <div class="sub-heading mt-sm">
              <p>With a core focus on data leads and phone call generation we have established ourselves as an authority in Legal Marketing.</p>
              <p>Fill your pipeline with leads and double your client intake!</p>
            </div>

            <div class="services-list-container mt-sm">
              <ul class="services-list">
                <li>Exclusive Live Calls and Web Leads</li>
                <li>Multi-Category Options</li>
                <li>Pay Per Lead</li>
                <li>No Commitment</li>
              </ul>
            </div>

            <div class="button-block mt-sm">
              <a href="/contact-us">
                <button class="button">Book a call with us</button>
              </a>
            </div>
            <img class="left-arrow" src="../../assets/img/left-arrow.png" alt="left-arrow">
          </div>
        </div>

        <div class="box">
          <div class="inbounds-container">
            <!--<h3 class="heading inbounds-heading">Your Leader in Legal</h3>-->
            <div class="img-container">
                <img class="wise-man-gif" src="../../assets/img/svg/undraw_revenue_re_2bmg.svg" alt="">
            </div>
            <div class="button-block mobile mt-sm">
              <a href="/contact-us">
                <button class="button">Book a call with us</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <p class="small-text">Receive two free leads when you get started with us!</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../assets/styles/component_styles/create_account';

.inbounds-logo {
  background-image: url("../../assets/img/inbounds.com.png");
  background-size: contain;
  background-repeat: no-repeat;
  filter: invert(69%) sepia(37%) saturate(604%) hue-rotate(181deg) brightness(96%) contrast(88%);
  width: 300px;
  height: 50px;
}

.container .wrapper .row {
  justify-content: left;
  .small-text {
    font-size: 15px;
    margin-left: 15px;
  }
}

.left-arrow {
  height: 80px;
  position: absolute;
  bottom: 0;
  margin-left: 230px;
}

.wise-man-gif {
  max-width: 270px;
  width: 100%;
}

.button-block.mobile {
  display: none;
}

@media screen and (max-width: 1000px) {
  .wise-man-gif {
    max-width: 300px;
  }
}

@media(max-width: 750px) {
  .container .wrapper .row .small-text {
    margin-left: 0;
    text-align: center;
  }
}

@media screen and (max-width: 670px) {
  .inbounds-logo {
    display: none;
  }

  .button-block.mt-sm {
    display: none;
  }

  .button-block.mobile {
    display: block;
  }

  .left-arrow {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .wise-man-gif {
    max-width: 240px;
  }
}
</style>