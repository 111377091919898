<script>
export default {
  name: "SignUpToday"
}
</script>

<template>
  <div class="container sign-up-today-container">
    <div class="wrapper">
      <div class="row">
        <div class="box">
          <div class="rocket-man-icon"></div>
        </div>
      </div>

      <div class="row">
        <div class="box">
          <div class="inner-container">
            <h3 class="heading sign-up-heading">
              Your firm works hard.
              <br/>
              Let us deliver the quality leads your firm deserves
              <br/>
              Drive <span class="highlight-word">revenue</span>.
              Experience <span class="highlight-word">growth</span>.
              Get real <span class="highlight-word">results</span>.
            </h3>
            <div class="button-block">
              <a href="/contact-us">
                <button class="button sign-up-button">Book a call with us</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../assets/styles/component_styles/sign-up-today.scss';

.highlight-word {
  color: #0e86d3;
}

.inner-container {
  text-align: center;
}

.button-block {
  justify-content: center;
}

.rocket-man-icon {
  background: url("../../assets/img/svg/undraw_team_collaboration_re_ow29.svg") no-repeat center;
  background-size: contain;
  height: 400px;
  width: 100%;
}

@media(max-width: 475px) {
  .rocket-man-icon {
    height: 300px;
  }
}

@media (max-width: 300px) {
  .rocket-man-icon {
    height: 200px;
  }
}
</style>