<script>
import CreateAccount from "@/components/shared/CreateAccount"
import ServicesInformation from "@/components/shared/ServicesInformation"
import AboutUs from "@/components/shared/AboutUs"
import GeoLocationBenefits from "@/components/shared/GeoLocationBenefits"
import Traffic from "@/components/shared/Traffic"
import SignUpToday from "@/components/shared/SignUpToday"

export default {
  name: "Home",
  components: {
    CreateAccount,
    ServicesInformation,
    AboutUs,
    GeoLocationBenefits,
    Traffic,
    SignUpToday,
  }
}
</script>

<template>
  <create-account/>
  <services-information/>
  <about-us />
  <geo-location-benefits/>
  <traffic/>
  <sign-up-today/>
</template>