<script>
export default {
  name: "GeoLocationBenefits"
}
</script>

<template>
  <div class="container geo-location-benefits-container">
    <div class="wrapper">
      <div class="row">
        <div class="box">
          <h3 class="heading geo-location-benefits-header">
            <strong class="strong-text">Maximize</strong> your customer base in your locale with Geo Targeting.
          </h3>
        </div>
        <div class="box map-container">
          <img class="united-states-map-icon" src="../../assets/img/svg/united-states-map.svg" alt="">
          <img class="pin bounce-1" src="../../assets/img/svg/pin.svg" alt="pin">
          <img class="pin bounce-1" src="../../assets/img/svg/pin.svg" alt="pin">
          <img class="pin bounce-1" src="../../assets/img/svg/pin.svg" alt="pin">
          <img class="pin bounce-1" src="../../assets/img/svg/pin.svg" alt="pin">
          <img class="pin bounce-1" src="../../assets/img/svg/pin.svg" alt="pin">
          <img class="pin bounce-1" src="../../assets/img/svg/pin.svg" alt="pin">
          <img class="pin bounce-1" src="../../assets/img/svg/pin.svg" alt="pin">
          <img class="pin bounce-1" src="../../assets/img/svg/pin.svg" alt="pin">
          <img class="pin bounce-1" src="../../assets/img/svg/pin.svg" alt="pin">
          <img class="pin bounce-1" src="../../assets/img/svg/pin.svg" alt="pin">
          <img class="pin bounce-1" src="../../assets/img/svg/pin.svg" alt="pin">
        </div>
      </div>

      <div class="row">
        <div class="box geo-location-subtext-container">
          <p class="sub-text">
            <strong class="strong-text">Geo-targeted</strong> consumers reside in the areas that are best for you and your firm down to their zip code.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../assets/styles/component_styles/geo-locaation-benefits.scss';
.wrapper .row .map-container {
  position: relative;
  max-width: 450px;
  margin-top: 10px;
  .pin {
    width: 5%;
    max-width: 450px;
    position: absolute;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    //filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(285deg) brightness(107%) contrast(106%);
    filter: invert(13%) sepia(81%) saturate(1741%) hue-rotate(196deg) brightness(95%) contrast(97%);
  }
  img.pin:nth-child(2) {
    top: 23%;
    right: 9%;
  }
  img.pin:nth-child(3) {
    top: 29%;
    right: 35%;
  }
  img.pin:nth-child(4) {
    top: 50%;
    right: 16%;
  }
  img.pin:nth-child(5) {
    top: 61%;
    right: 31%;
  }
  img.pin:nth-child(6) {
    top: 72%;
    right: 50%;
  }
  img.pin:nth-child(7) {
    top: 87%;
    right: 84%;
  }
  img.pin:nth-child(8) {
    top: 32%;
    right: 93%;
  }
  img.pin:nth-child(9) {
    top: 20%;
    right: 62%;
  }
  img.pin:nth-child(10) {
    top: 43%;
    right: 51%;
  }
  img.pin:nth-child(11) {
    top: 8%;
    right: 81%;
  }
  img.pin:nth-child(12) {
    top: 59%;
    right: 76%;
  }
  .bounce-1 {
    animation-name: bounce-1;
    animation-timing-function: ease;
  }

  @keyframes bounce-1 {
    0%   { transform: scale(1,1)    translateY(0); }
    10%  { transform: scale(1.1,.9) translateY(0); }
    30%  { transform: scale(.9,1.1) translateY(-20px); }
    50%  { transform: scale(1,1)    translateY(0); }
    57%  { transform: scale(1,1)    translateY(-7px); }
    64%  { transform: scale(1,1)    translateY(0); }
    100% { transform: scale(1,1)    translateY(0); }
  }
}
</style>