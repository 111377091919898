<script>
export default {
  name: "AboutUs"
}
</script>

<template>
  <div class="container about-us-container">
    <div class="wrapper">
      <div class="card">
        <div class="card-img">
          <img src="../../assets/img/svg/undraw_group_hangout_re_4t8r.svg" alt="">
        </div>

        <div class="card-text-container">
          <h3 class="card-header">Convert more Leads</h3>
          <p class="card-text">
            Your firm thrives on quality clients. People who see our ads get taken through our
            proprietary qualification funnel. If they make it through and match your area of
            law and state we deliver in real time to your email, phone or CRM.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-img">
          <img src="../../assets/img/svg/undraw_business_deal_re_up4u.svg" alt="">
        </div>
        <div class="card-text-container">
          <h3 class="card-header">Focus on your clients</h3>
          <p class="card-text">
            Relieve the stress of online marketing and focus on providing service to your clients.
            Using our nurturing funnels, we can deliver leads in high volume and quality so you can
            focus on what matters most!
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-img">
          <img src="../../assets/img/svg/undraw_business_plan_re_0v81.svg" alt="">
        </div>

        <div class="card-text-container">
          <h3 class="card-header">Grow your Firm</h3>
          <p class="card-text">
            Efficiency is our top priority. We have spent years fine tuning the
            verification process to provide you with highly qualified leads. Leads that
            convert to business and revenue. We have established solid, long-lasting
            relationships with our clients. They know they can turn to us for insight and
            solid leads.
          </p>
        </div>
      </div>
    </div>

    <div class="row results-row">
      <div class="box results-box">
        <div class="results-container">
          <p class="bold-text">Web Leads</p>
          <img class="results-icon smaller-icon" src="../../assets/img/svg/plus.svg" alt="">
          <p class="bold-text">Live Calls</p>
          <img class="results-icon smaller-icon" src="../../assets/img/svg/plus.svg" alt="">
          <p class="bold-text">Exclusivity</p>
          <img class="results-icon smaller-icon" src="../../assets/img/svg/plus.svg" alt="">
          <p class="bold-text">Real Time Delivery</p>
          <img class="results-icon smaller-icon" src="../../assets/img/svg/equals.svg" alt="">

          <ul class="results-list">
            <li><strong class="strong-text">Real</strong> Time Results</li>
            <li><strong class="strong-text">Real</strong> Profit</li>
            <li><strong class="strong-text">Real</strong> Firm Growth</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../assets/styles/component_styles/about_us.scss';

.strong-text {
  color: #12619e;
}
.bold-text {
  font-weight: bolder;
  font-size: 25px;
  text-align: center;
}
</style>